window.ExpressenAnalytics = window.ExpressenAnalytics || [];
export default function accountMenu() {
  const element = document.getElementById("account-menu");
  const header = document.getElementById("header");
  const toggleButton = header && header.getElementsByClassName("site-header__link--my-account")[0];
  if (!element || !toggleButton) return;
  const ctaLink = header.getElementsByClassName("site-header__link--cta")[0];
  element.addEventListener("click", event => event.stopPropagation());
  element.addEventListener("focusout", focusToggleButton);
  toggleButton.addEventListener("click", toggleDropdown);
  toggleButton.addEventListener("click", trackLinkClick);
  if (ctaLink) ctaLink.addEventListener("click", trackLinkClick);
  try {
    const user = window.Exp.user;
    const userName = user.givenName;
    const userInfo = element.getElementsByClassName("account-menu__user-info")[0];
    const [userNameElement] = userInfo.children;
    if (userName && userNameElement) {
      userNameElement.textContent = userName;
    }

    // eslint-disable-next-line no-empty
  } catch (error) {}
  function trackLinkClick(event) {
    event.stopPropagation();
    const isHidden = element.classList.contains("hidden");
    const isMyAccountLinkOpen = this === toggleButton && !isHidden;
    const buyPremiumLink = this === ctaLink;
    if (!buyPremiumLink && !isMyAccountLinkOpen) return;
    window.ExpressenAnalytics.push(analytics => {
      analytics.track.customActive("navigation", "click", isMyAccountLinkOpen ? "accountMenu|openMenu" : "buy_premium");
    });
  }
  function toggleDropdown(event) {
    event.stopPropagation();
    const isHidden = element.classList.toggle("hidden");
    if (isHidden) {
      document.body.removeEventListener("click", toggleDropdown);
    } else {
      document.body.addEventListener("click", toggleDropdown);
      element.focus();
    }
  }
  function focusToggleButton(event) {
    if (!element.contains(event.relatedTarget)) {
      toggleButton.focus();
    }
  }
}